import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import Pagina from '../components/Pagina'
import BackgroundImg from 'gatsby-background-image'
import Swiper from 'react-id-swiper'

import iconTitle from '../images/projeto-h/title.svg'
import iconOne from '../images/projeto-h/one.svg'
import iconTwo from '../images/projeto-h/two.svg'
import iconThree from '../images/projeto-h/three.svg'
import iconFour from '../images/projeto-h/four.svg'
import iconFive from '../images/projeto-h/five.svg'
import valorEconomico from '../images/projeto-h/valor_economico.svg'
import portalSustentabilidade from '../images/projeto-h/noticias_portal_sustentabilidade.svg'
import noticiasEco from '../images/projeto-h/noticias_eco.svg'
import investSp from '../images/projeto-h/noticias_invest_sp.svg'
import infoMoney from '../images/projeto-h/noticias_info_money.svg'
import portalSustentabilidade2 from '../images/projeto-h/noticias_portal_sustentabilidade_2.svg'
import valorEconomico2 from '../images/projeto-h/noticias_valor_economico.svg'
// import oluc from '../images/projeto-h/oluc.svg'
import circle from '../images/projeto-h/circle.svg'
// import olucMobile from '../images/projeto-h/oluc_mobile.svg'
import fontOluc from '../images/projeto-h/font_mobile.svg'
import industria from '../images/projeto-h/industria.svg'

import pdf from '../content/Lwart investirá R� 1 bi para ampliar rerrefino de óleos lubrificantes _ Empresas _ Valor Econômico.pdf'

import './styles/projeto-h.scss'

const ProjetoH = ({ data }) => {
  console.log(data)
  return (
    <>
      <Pagina pagina={data.pagina} />
      <Banner banner={data.paralax.childImageSharp.gatsbyImageData} />
      <Introducao />
      <Conheca industria={data.industria.childImageSharp.gatsbyImageData} />
      <Midia />
      <Carousel />
      <OLUC />
    </>
  )
}

const Banner = ({ banner }) => {
  const image = getImage(banner)
  const bgImage = convertToBgImage(image)

  return (
    <div>
      {/* Desktop */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-lg-flex d-none"
      >
        <div className="container h-100">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="p-0 banner-title text-center">
              <img src={iconTitle} className='w-30 mb-5' />
              <h2 className='text-white text-uppercase'><span>+</span> sustentabilidade <span>+</span> inovação <span>+</span> responsabilidade</h2>
            </div>
          </div>
        </div>
      </BackgroundImg>

      {/* Mobile */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-lg-none d-flex"
      >
        <div className="container h-100">
          <div className="d-flex align-items-center justify-content-center h-100">
            <div className="p-0 banner-title text-center">
              <img src={iconTitle} className='mb-5' />
              <h6 className='text-white text-uppercase'><span>+</span> sustentabilidade <span>+</span> inovação <span>+</span> responsabilidade</h6>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  )
}

const Introducao = () => {
  return (
    <div className='introducao-carousel'>
      <div className='container py-5'>
        <h1>O projeto que transformará a Lwart na<br /> segunda maior rerrefinaria do mundo!</h1>
        <div className='row mt-5 mx-md-0 mx-1'>
          <div className='col-md-5 col-12 pl-0 mr-lg-4 mb-lg-0 mb-3'>
            <p>A Lwart Soluções Ambientais anunciou o investimento de 1 bilhão de reais no Projeto H+, a expansão do seu parque produtivo em Lençóis Paulista, no interior do Estado de São Paulo, com conclusão prevista para 2025.</p>
            <p>O valor vem sendo investido na construção de uma nova fábrica de rerrefino de óleo lubrificante usado, que permitirá aumentar a capacidade de produção de óleo básico de alta performance, conhecido como Grupo II, produto este que o Brasil precisa importar, pois não é autossuficiente em sua produção.</p>
            <p>O projeto H+ irá beneficiar o desenvolvimento econômico e ambiental do país.</p>
          </div>
          <div className='col-md-5 col-12 position-relative aspas'>
            <p><span className='left'>&ldquo;</span>A Lwart coleta o óleo lubrificante usado em todo território nacional e com a expansão devemos crescer ainda mais em capilaridade. Isso vai garantir que um maior volume do resíduo seja gerido de forma correta e que haja, consequentemente, uma maior produção de óleo básico Grupo II no país.</p>
            <p>Estamos falando de alta tecnologia para transformar um resíduo em um óleo de extrema qualidade. Como o processo de rerrefino pode ser realizado infinitas vezes, há um impacto muito positivo na preservação ambiental.<span className='ml-1'>&rdquo;</span></p>
            <p>Thiago Trecenti<br />CEO da Lwart</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const Conheca = () => {
  return (
    <div className='bg-blue-projeto-h z-index-blue'>
      <div className='container py-5'>
        <h1 className='text-white text-center'>Conheça o projeto</h1>
        {/* <GatsbyImage image={industria} className='mt-5 mb-3 w-100' /> */}
        <img src={industria} className='mt-5 mb-3 w-100' />
        {/* Desktop */}
        <div className='row mt-5 d-md-flex d-none'>
          <div className='col'>
            <img src={iconOne} />
            <h2 className='text-white mb-0 mt-4'>1 bilhão</h2>
            <p className='text-white'>de reais investidos</p>
          </div>
          <div className='col'>
            <img src={iconTwo} />
            <h2 className='text-white mb-0 mt-4'>1.200</h2>
            <p className='text-white'>novos postos indiretos de trabalho durante a construção</p>
          </div>
          <div className='col'>
            <img src={iconThree} />
            <h2 className='text-white mb-0 mt-4'>+400</h2>
            <p className='text-white'>empregos diretos previstos na operação após a construção</p>
          </div>
          <div className='col'>
            <img src={iconFour} />
            <h2 className='text-white mb-0 mt-4'>+120 MM</h2>
            <p className='text-white'>de litros por ano de capacidade produtiva</p>
          </div>
          <div className='col'>
            <img src={iconFive} />
            <h2 className='text-white mb-0 mt-4'>26 mil m²</h2>
            <p className='text-white'>de área construída na expansão</p>
          </div>
        </div>
        {/* Mobile */}
        <div className='mt-5 d-md-none d-flex justify-content-center'>
          <div>
            <div className='row'>
              <div className='col-6'>
                <img src={iconOne} />
              </div>
              <div className='col'>
                <h2 className='text-white mb-0 mt-4'>1 bilhão</h2>
                <p className='text-white'>de reais investidos</p>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-6'>
                <img src={iconTwo} />
              </div>
              <div className='col'>
                <h2 className='text-white mb-0 mt-4'>1.200</h2>
                <p className='text-white'>novos postos indiretos de trabalho durante a construção</p>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-6'>
                <img src={iconThree} />
              </div>
              <div className='col'>
                <h2 className='text-white mb-0 mt-4'>+400</h2>
                <p className='text-white'>empregos diretos previstos na operação após a construção</p>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-6'>
                <img src={iconFour} />
              </div>
              <div className='col'>
                <h2 className='text-white mb-0 mt-4'>+120 MM</h2>
                <p className='text-white'>de litros por ano de capacidade produtiva</p>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-6'>
                <img src={iconFive} />
              </div>
              <div className='col'>
                <h2 className='text-white mb-0 mt-4'>26 mil m²</h2>
                <p className='text-white'>de área construída na expansão</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Midia = () => {
  return (
    <div className='midia'>
      <div className='container py-5'>
        <div className='row justify-content-center position-relative'>
          <img src={circle} className='circle w-100' />
          <div className='col-md-4 col-12'>
            <h1>Projeto H+<br /> na mídia</h1>
            <p>Um dos maiores jornais do Brasil no ramo de economia e negócios, o Valor Econômico, conversou com exclusividade com Thiago Trecenti.</p>
            <a href={pdf} target="_blank" rel="noopener noreferrer" >Leia na íntegra</a>
          </div>
          <div className='col-md-4 col-12 mt-lg-0 mt-3 d-flex justify-content-center'>
            <img src={valorEconomico} className='position-absolute noticia' />
          </div>
        </div>
      </div>
    </div>
  )
}

const Carousel = () => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      1200: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  }

  return (
    <div className='introducao-carousel z-index-dark-blue py-5'>
      <div className='container py-5'>
        <p className='text-white mt-lg-0 mt-md-0 mt-5 pt-lg-0 pt-md-0 pt-5 ml-2'>Confira outros<br /> destaques na mídia:</p>
        <div className='mb-5 pb-5 position-relative'>
          <Swiper {...params}>
            <div className='boxSwiper'>
              <img src={portalSustentabilidade} /><br />
              <a style={{ marginLeft: 5 + 'px' }} href='https://portalsustentabilidade.com/2023/10/25/lwart-solucoes-ambientais-investe-na-construcao-de-nova-fabrica-de-rerrefino-de-oleo-lubrificante-usado/' target="_blank" rel="noopener noreferrer" >Leia na íntegra</a>
            </div>
            <div className='boxSwiper'>
              <img src={noticiasEco} /><br />
              <a style={{ marginLeft: 5 + 'px' }} href='https://jornaloeco.com.br/economia/lwart-solucoes-ambientais-investe-r-1-bilhao-na-construcao-de-nova-fabrica-de-rerrefino-de-oleo-lubrificante-usado/' target="_blank" rel="noopener noreferrer" >Leia na íntegra</a>
            </div>
            <div className='boxSwiper'>
              <img src={investSp} /><br />
              <a style={{ marginLeft: 5 + 'px' }} href='https://www.investe.sp.gov.br/noticia/lwart-solucoes-ambientais-investe-na-construcao-de-nova-fabrica-de-rerrefino-de-oleo-lubrificante-usado/' target="_blank" rel="noopener noreferrer" >Leia na íntegra</a>
            </div>
            <div className='boxSwiper'>
              <img src={valorEconomico2} /><br />
              <a style={{ marginLeft: 5 + 'px' }} href='https://valor.globo.com/financas/noticia/2024/03/19/lwart-emite-1a-debenture-verde-para-financiar-seu-plano-de-crescimento.ghtml' target="_blank" rel="noopener noreferrer" >Leia na íntegra</a>
            </div>
            <div className='boxSwiper'>
              <img src={infoMoney} /><br />
              <a style={{ marginLeft: 5 + 'px' }} href='https://www.infomoney.com.br/business/lwart-recicladora-de-oleo-lubrificante-levanta-r-500-milhoes-expandir-operacao/' target="_blank" rel="noopener noreferrer" >Leia na íntegra</a>
            </div>
            <div className='boxSwiper'>
              <img src={portalSustentabilidade2} /><br />
              <a style={{ marginLeft: 5 + 'px' }} href='https://portalsustentabilidade.com/2024/03/23/lwart-solucoes-ambientais-conclui-captacao-de-r-500-milhoes-para-ampliar-negocios-esg/' target="_blank" rel="noopener noreferrer" >Leia na íntegra</a>
            </div>
          </Swiper>
        </div>
      </div>
      <div className='d-lg-none d-flex justify-content-center mt-5 position-relative'>
        <img src='https://lwart-static-assets-prd.s3.amazonaws.com/oluc_mobile.svg' className='position-absolute oluc-position' />
      </div>
    </div>
  )
}

const OLUC = () => {
  return (
    <div>
      {/* Desktop */}
      <div className='faixa-blue d-lg-none'></div>
      <div className='oluc-projeto-h d-lg-flex d-none'>
        <div className='container py-5 position-relative'>
          <img src='https://lwart-static-assets-prd.s3.amazonaws.com/oluc_desktop.svg' className='position-absolute teste' />
        </div>
      </div>
      {/* Mobile */}
      <div className='oluc-projeto-h-mobile d-lg-none d-flex justify-content-center'>
        <div className='container py-5'>
          <div className='d-flex justify-content-center'>
            <img src={fontOluc} width={250} />
          </div>
          <p className='font-Spinnaker text-white mt-5'>Mais conhecido como Óleo Lubrificante Usado ou Contaminado, o OLUC é aquele resíduo preto que sai das máquinas e motores quando é feita a troca. Se descartado de forma incorreta, um único litro dele pode contaminar até 1 milhão de litros de água. O rerrefino é seu único destino correto previsto pela lei brasileira. Assim, é possível devolvê-lo ao mercado limpo e pronto para ser utilizado novamente.</p>
        </div>
      </div>
      <div className="gradient-blue-to-green"></div>
    </div>
  )
}

export default ProjetoH

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/projeto-h/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "projeto-h/banner.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  industria: file(relativePath: {eq: "projeto-h/infografico_projeto.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
